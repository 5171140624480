export const data = [
    {
        title: 'Barcos',
        class: 'fa fa-ship nav-icon',
        icon: 'nav-icon fas fa-book',
        href: 'Barcos',
        rolPermitted: ['AdminLogistica', 'ClienteEXP', 'LogisticaOperacionesEXP', 'DataMaestraEXP', 'AdministradorEXP', 'OperadorPTEXP', 'SupervisoraEXP', 'ComercialOperacionesEXP', 'SupervisorPTEXP'],
    },
    {
        title: 'Patios',
        class: 'fa fa-map-marker nav-icon',
        icon: 'nav-icon fas fa-book',
        href: 'Patios',
        rolPermitted: ['AdminLogistica', 'ClienteEXP', 'LogisticaOperacionesEXP', 'DataMaestraEXP', 'AdministradorEXP', 'SupervisorPTEXP'],

    },
    {
        title: 'Navieras',
        class: 'fa fa-building nav-icon',
        icon: 'nav-icon fas fa-book',
        href: 'navieras',
        rolPermitted: ['AdminLogistica', 'ClienteEXP', 'LogisticaOperacionesEXP', 'DataMaestraEXP', 'AdministradorEXP', 'SupervisorPTEXP'],

    },
    {
        title: 'Eventos',
        class: 'fa fa-share nav-icon',
        icon: 'nav-icon fas fa-book',
        href: 'eventos',
        rolPermitted: ['AdminLogistica', 'ClienteEXP', 'LogisticaOperacionesEXP', 'DataMaestraEXP', 'AdministradorEXP', 'SupervisorPTEXP'],

    },
    {
        title: 'Departamento',
        class: 'fa fa-file nav-icon',
        icon: 'nav-icon fas fa-book',
        href: 'departamento',
        rolPermitted: ['AdminLogistica', 'ClienteEXP', 'LogisticaOperacionesEXP', 'DataMaestraEXP', 'AdministradorEXP', 'SupervisorPTEXP'],
    },
    {
        title: 'Municipio',
        class: 'fa fa-file nav-icon',
        icon: 'nav-icon fas fa-book',
        href: 'municipio',
        rolPermitted: ['AdminLogistica', 'ClienteEXP', 'LogisticaOperacionesEXP', 'DataMaestraEXP', 'AdministradorEXP', 'SupervisorPTEXP'],
    },
    {
        title: 'Clientes',
        class: 'fa fa-file nav-icon',
        icon: 'nav-icon fas fa-book',
        href: 'cliente',
        rolPermitted: ['AdminLogistica', 'ClienteEXP', 'LogisticaOperacionesEXP', 'DataMaestraEXP', 'AdministradorEXP', 'SupervisorPTEXP'],
    },
    {
        title: 'Fletes',
        class: 'fa fa-credit-card nav-icon',
        icon: 'nav-icon fas fa-book',
        href: 'fletes',
        rolPermitted: ['AdminLogistica', 'ClienteEXP', 'LogisticaOperacionesEXP', 'DataMaestraEXP', 'AdministradorEXP', 'SupervisorPTEXP'],
    },
    {
        title: 'Movimientos',
        class: 'fa fa-truck nav-icon',
        icon: 'nav-icon fas fa-book',
        href: 'movimientos',
        rolPermitted: ['AdminLogistica', 'ClienteEXP', 'LogisticaOperacionesEXP', 'DataMaestraEXP', 'AdministradorEXP', 'SupervisorPTEXP'],
    },
    {
        title: 'Facturación',
        class: 'fa fa-file nav-icon',
        icon: 'nav-icon fas fa-book',
        href: 'facturacion',
        rolPermitted: ['AdminLogistica', 'ClienteEXP', 'LogisticaOperacionesEXP', 'DataMaestraEXP', 'AdministradorEXP', 'SupervisorPTEXP'],
    },
    {
        title: 'Bitacora de pagos',
        class: 'fa fa-file nav-icon',
        icon: 'nav-icon fas fa-book',
        href: 'bitacora',
        rolPermitted: ['AdminLogistica', 'ClienteEXP', 'LogisticaOperacionesEXP', 'DataMaestraEXP', 'AdministradorEXP', 'SupervisorPTEXP'],
    },
    {
        title: 'Facturas Quedan',
        class: 'fa fa-file nav-icon',
        icon: 'nav-icon fas fa-book',
        href: 'quedan',
        rolPermitted: ['AdminLogistica', 'ClienteEXP', 'LogisticaOperacionesEXP', 'DataMaestraEXP', 'AdministradorEXP', 'SupervisorPTEXP'],
    }
]