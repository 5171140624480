export const ENV = 'PRD';

const routes = {
  LOCAL : 'http://localhost:8080/api',
  DESARROLLO : 'https://e9a099e9.sitepreview.org/logisticaback/api',
  QAS : 'http://192.168.24.181/ApiExportacion/public/index.php/api',
  PRD : 'https://75dd8788.sitepreview.org/logisticaback/api',
}

const routesIntegraciones = {
  LOCAL: 'http://192.168.24.46:8026/',
  DESARROLLO : 'http://192.168.24.46:8026/',
  QAS : 'http://192.168.24.46:8026/',
  PRD : 'http://192.168.23.76:8026/',
}
const routesApiCertificados = {
  LOCAL: 'http://localhost/apicertificados/index.php',
  DESARROLLO : 'http://192.168.24.58/ApiLogisticaDEV/index.php/',
  QAS : 'http://192.168.24.56/ApiLogistica/index.php/',
  PRD : 'http://apps.grupocassa.com:8020/ApiLogistica/index.php/',
}

const routesApk = {
  LOCAL : 'http://192.168.24.181/ApiExportacion/public/',
  DESARROLLO : 'http://192.168.24.178/ApiExportacion/public/',
  QAS : 'http://192.168.24.181/ApiExportacion/public/',
  PRD : 'http://168.243.156.98/ApiExportacion/public/',
}
const routesCorreo = {
  LOCAL : 'http://192.168.24.181/Exportacion/',
  DESARROLLO : 'http://192.168.24.178/Exportacion/',
  QAS : 'http://192.168.24.181/Exportacion/',
  PRD : 'http://168.243.156.98/Exportacion/',
}
const routesSocket = {
  LOCAL : '127.0.0.1',
  DESARROLLO : '192.168.24.178',
  QAS : '192.168.24.181',
  PRD : '192.168.23.42',
}

export const SAPpass = 'Emercassa21'
export const SAPuser = 'ecalderon'
export const path = routes[ENV]
export const pathIntegraciones = routesIntegraciones[ENV]
export const apicertificados = routesApiCertificados[ENV]
export const pathApk = routesApk[ENV]
//Indica el folder donde reside el proyecto en el servidor
export const server_folder = '/Proveedores'
//NO MODIFICAR-- DATOS DE PUSHER(WEB SOCKET)
// export const PUSHER_URL = '192.168.24.178'
// export const PUSHER_URL = '127.0.0.1'
export const PUSHER_URL = routesSocket[ENV]
export const PUSHER_APP_KEY = 'ASDASD2121'
export const pathCorreo = routesCorreo[ENV]
